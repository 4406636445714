.portfolio {
    position: relative;
    box-shadow: 0 8px 12px rgba(0,0,0,0.25);
}

.portfolio header {
    background-color: var(--color-primary);
}

@media (min-width: 768px) {
    .portfolio {
        display: grid;
        grid-template-columns: 75px 1fr;
    }

    .projects {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
    }
}

@media (min-width: 992px) {
    .projects {
        grid-template-columns: repeat(3, 1fr);
    }
}
