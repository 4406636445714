:root {
  /* Colors */
  --color-white: #ffffff;
  --color-primary: #2D4766;
  --color-secondary: #7DAA92;
  --color-secondary-dark: #3c5d4b;
  --color-dark: #000;

  /* Fonts */
  --font-primary: 'Poppins', sans-serif;

  /* Font Weight */
  --fontWeight-lt: 300;
  --fontWeight-rg: 400;
  --fontWeight-md: 500;
  --fontWeight-sb: 600;
  --fontWeight-b: 700;

  /* Font Sizes */
  --font-sm: 0.875rem;
  --font-rg: 1rem;
  --font-md: 1.25rem;
  --font-mdlg: 1.5rem;
  --font-lg: 2rem;
  --font-xl: 3rem;
  --font-xxl: 5rem;

  /* Spacing */
  --space-sm: 0.5rem;
  --space-rg: 1rem;
  --space-md: 1.5rem;
  --space-lg: 2rem;
  --space-xl: 3rem;
}

.App {
  position: relative;
}

body {
  background-color: var(--color-dark);
  font-family: var(--font-primary);
}

h1,
h2 {
  font-weight: var(--fontWeight-lt);
}

h2 {
  font-size: var(--font-lg);
}

h3 {
  font-size: var(--font-md);
}

.vertical-header h2 {
  text-align: center;
  color: var(--color-white);
  text-transform: uppercase;
}

.container {
  width: 100%;
  padding-right: var(--space-rg);
  padding-left: var(--space-rg);
  margin-right: auto;
  margin-left: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

/*************** 
Links 
***************/
a {
  color: var(--color-white);
  text-decoration: none;
  padding: 0.5rem;
}

a:hover {
  color: var(--color-secondary);
}

a.link-light {
  color: var(--color-white);
  text-decoration: none;
}

a.link-light:hover,
a.link-light:active,
a.link-light:focus {
  color: var(--color-dark);
}

/*************** 
Media Queries
***************/
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .vertical-header h2 {
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-align: start;
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
    color: var(--color-white);
    font-weight: 600;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
