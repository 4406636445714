.portfolioCard a {
    display: inline;
    padding: 0;
}

.portfolioCard img {
    filter: grayscale(100%);
}

.portfolioCard img:hover {
    filter: none;
}