.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: var(--space-rg);
    background-color: rgba(0,0,0,0.8);
    text-align: center;
}

.footer a span {
    font-size: var(--font-mdlg);
}