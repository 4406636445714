.experience {
    background-color: var(--color-white);
}

.experience header {
    background-color: var(--color-secondary-dark);
}

.experience .content {
    padding: var(--space-rg);
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .experience {
        display: grid;
        grid-template-columns: 1fr 75px;
    }

    .experience .content {
        grid-column: 1;
        grid-row: 1;
        padding-top: var(--space-lg);
        padding-bottom: var(--space-lg);
    }

    .experience header {
        grid-column: 2;
        grid-row: 1;
    }

    
}