.experienceCard {
    max-width: 1000px;
    margin: auto;
    padding: var(--space-rg) 0;
}

.company {
    color: var(--color-secondary-dark);
    font-weight: var(--fontWeight-sb);
}

.date{
    margin-bottom: 1rem;
}

.title {
    text-transform: uppercase;
    letter-spacing: 2px;
}

@media (min-width: 768px) {
    .experienceCard .content {
        display: grid;
        grid-template-columns: 250px 1fr;
    }
}
