.skills {
    background: url('/public/images/prague_sm.webp');
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-dark);
    padding-bottom: 60px;
}

.skills header {
    background-color: var(--color-primary);
}

.skillCards {
    margin-bottom: -1px;
}

@media (min-width: 768px) {
    .skills {
        display: grid;
        grid-template-columns: 75px 1fr;
    }

    .skillCards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}