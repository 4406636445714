.skillCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: var(--space-rg);
}

.skillCard:first-child {
    background-color: rgba(0,0,0,0.6);
    color: var(--color-white);
}

.skillCard:first-child h3 {
    color: var(--color-white);
}

.skillCard:last-child {
    background-color: rgba(0,0,0,0.6);
    color: var(--color-white);
}

.skillCard:last-child h3 {
    color: var(--color-secondary-white);
}

.skillCard ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
}

.skillCard ul li {
    padding: 0.5rem 1rem;
}

@media (min-width: 768px) {
    .skillCard:first-child {
        border-right: 2px dotted rgba(255, 255, 255, 0.5);
    }
}